import request from "../utils/request";

// 后台登录
export function adminLogin(params) {
  return request({
    url: "6510e8e2dbe71",
    method: "get",
    params,
  });
}

// 获取管理员信息
export function admininfo(params) {
  return request({
    url: "6523a8584187e",
    method: "get",
    params,
  });
}

// 修改管理员密码
export function changeadminpwd(params) {
  return request({
    url: "6523a9c2c27c4",
    method: "get",
    params,
  });
}

// 编辑管理员账号
export function editadmin(params) {
  return request({
    url: "6523a959d4d6c",
    method: "get",
    params,
  });
}

// 首页数据
export function homeData(params) {
  return request({
    url: "6510e8f28d5c8",
    method: "get",
    params,
  });
}

// 洗车量统计
export function orderStatic(params) {
  return request({
    url: "66ea775bb202c",
    method: "get",
    params,
  });
}

// 门店列表
export function shopList(params) {
  return request({
    url: "6510e91581bd4",
    method: "get",
    params,
  });
}

// 城市列表
export function cityList(params) {
  return request({
    url: "6510ea441db50",
    method: "get",
    params,
  });
}

// 新增门店
export function addshop(params) {
  return request({
    url: "6510ea5e9de01",
    method: "get",
    params,
  });
}

//门店详情
export function shopdec(params) {
  return request({
    url: "6510ea6930840",
    method: "get",
    params,
  });
}

//门店编辑
export function editshop(params) {
  return request({
    url: "6510ea77d3316",
    method: "get",
    params,
  });
}

//门店删除
export function delshop(params) {
  return request({
    url: "6510ea8284546",
    method: "get",
    params,
  });
}

// 服务管理
export function service(params) {
  return request({
    url: "6510ea8d6577a",
    method: "get",
    params,
  });
}

// 服务编辑
export function serviceedit(params) {
  return request({
    url: "6510ea9d005d3",
    method: "get",
    params,
  });
}

//账单列表
export function billList(params) {
  return request({
    url: "654b505fa0246",
    method: "get",
    params,
  });
}

// 订单列表
export function orderList(params) {
  return request({
    url: "6510eaac1458f",
    method: "get",
    params,
  });
}

//订单详情
export function orderDec(params) {
  return request({
    url: "6510eab6b9685",
    method: "get",
    params,
  });
}

// 用户列表
export function userList(params) {
  return request({
    url: "6510eac1ab849",
    method: "get",
    params,
  });
}

//用户详情
export function userDec(params) {
  return request({
    url: "6510ead28b092",
    method: "get",
    params,
  });
}

// 余额变动
export function balancechange(params) {
  return request({
    url: "6551e1c557f49",
    method: "get",
    params,
  });
}

// 修改用户余额
export function changeuserbalance(params) {
  return request({
    url: "6551de586c164",
    method: "get",
    params,
  });
}

// 冻结用户
export function Frozen(params) {
  return request({
    url: "655b0b0f1de69",
    method: "get",
    params,
  });
}

//专员开通
export function openZY(params) {
  return request({
    url: "6510eae036a12",
    method: "get",
    params,
  });
}

// 福利官开通
export function openFLG(params) {
  return request({
    url: "655afefdc3f21",
    method: "get",
    params,
  });
}

// 福利官列表
export function welfareList(params) {
  return request({
    url: "6510eaeaa0755",
    method: "get",
    params,
  });
}

//福利官详情+返佣记录列表
export function welfareDec(params) {
  return request({
    url: "6510eb518fd36",
    method: "get",
    params,
  });
}

// 取消福利官
export function cancelwelfare(params) {
  return request({
    url: "655b202de3908",
    method: "get",
    params,
  });
}

// 福利官团队列表
export function groupList(params) {
  return request({
    url: "655c538f26390",
    method: "get",
    params,
  });
}
// 承包商列表
export function contractorList(params) {
  return request({
    url: "6510e926a4e25",
    method: "get",
    params,
  });
}

// 新建承包商
export function addcontractor(params) {
  return request({
    url: "6510eb5cc8dbc",
    method: "get",
    params,
  });
}

//承包商详情+门店收益
export function contractordec(params) {
  return request({
    url: "6510eb7407b19",
    method: "get",
    params,
  });
}

//修改承包商密码
export function changecontractorpassword(params) {
  return request({
    url: "6510eb8e525d7",
    method: "get",
    params,
  });
}

//删除承包商
export function delcontractor(params) {
  return request({
    url: "6510eb990c140",
    method: "get",
    params,
  });
}

//加盟商列表
export function allianceBusinessList(params) {
  return request({
    url: "6510eba39aadb",
    method: "get",
    params,
  });
}

//新建加盟商
export function addallianceBusiness(params) {
  return request({
    url: "6510ebacca69b",
    method: "get",
    params,
  });
}

//加盟商详情
export function allianceBusinessdec(params) {
  return request({
    url: "6510ebba1eb71",
    method: "get",
    params,
  });
}

//修改加盟商密码
export function changeallianceBusinesspassword(params) {
  return request({
    url: "6510ebc59392a",
    method: "get",
    params,
  });
}

//删除加盟商
export function delallianceBusiness(params) {
  return request({
    url: "6510ebd2334fc",
    method: "get",
    params,
  });
}

//厂商列表
export function manufacturerList(params) {
  return request({
    url: "6510ea3bc6534",
    method: "get",
    params,
  });
}

//厂商详情+厂商门店收益
export function manufacturerdec(params) {
  return request({
    url: "6510ebe82f76a",
    method: "get",
    params,
  });
}

//厂商修改密码+厂商修改返佣比例
export function changemanufacturerpassword(params) {
  return request({
    url: "6510ebf3ee0e0",
    method: "get",
    params,
  });
}

//删除厂商
export function delmanufacturer(params) {
  return request({
    url: "6510ebfed4ef5",
    method: "get",
    params,
  });
}

//新建厂商
export function addmanufacturer(params) {
  return request({
    url: "6510ebdf5e574",
    method: "get",
    params,
  });
}

//代理列表
export function cityAgency(params) {
  return request({
    url: "6510ec1b7b5dd",
    method: "get",
    params,
  });
}

//新建代理
export function addcityAgency(params) {
  return request({
    url: "6510ec2765abb",
    method: "get",
    params,
  });
}

//代理详情
export function cityAgencydec(params) {
  return request({
    url: "6510ec32e2f30",
    method: "get",
    params,
  });
}

//代理修改密码
export function cityAgencychangeword(params) {
  return request({
    url: "6510ec3e922c3",
    method: "get",
    params,
  });
}

//删除代理
export function delcityAgency(params) {
  return request({
    url: "6510ec4b6fae7",
    method: "get",
    params,
  });
}

//次卡列表
export function timecardList(params) {
  return request({
    url: "6510ec5396bbb",
    method: "get",
    params,
  });
}

//新建次卡
export function addtimecard(params) {
  return request({
    url: "6510ec61ec9ee",
    method: "get",
    params,
  });
}

//次卡详情
export function timecarddec(params) {
  return request({
    url: "6510ec6b5a636",
    method: "get",
    params,
  });
}

//次卡编辑
export function edittimecard(params) {
  return request({
    url: "6510ec78b3c5c",
    method: "get",
    params,
  });
}

//次卡删除
export function deltimecard(params) {
  return request({
    url: "6510ec83c63c3",
    method: "get",
    params,
  });
}

//次卡启用/停用-
export function changetimecard(params) {
  return request({
    url: "6510ec8e3ffa5",
    method: "get",
    params,
  });
}

//多次卡（年卡）列表
export function yearcardList(params) {
  return request({
    url: "669e2a969062f",
    method: "get",
    params,
  });
}

//生成年卡并导出
export function addyearcard(params) {
  return request({
    url: "6510eca3e10b3",
    method: "get",
    params,
  });
}

//生成抖音次卡并导出
export function addtiktokcard(params) {
  return request({
    url: "6510ecadc4fb6",
    method: "get",
    params,
  });
}

// 录入抖音次卡
export function entertiktokcard(params) {
  return request({
    url: "65409d9bbf5cb",
    method: "get",
    params,
  });
}

//邀请管理
export function invite(params) {
  return request({
    url: "6510eccbcf488",
    method: "get",
    params,
  });
}

//邀请管理编辑
export function editinvite(params) {
  return request({
    url: "6510ecd88e654",
    method: "get",
    params,
  });
}

//福利官管理
export function welfareinfo(params) {
  return request({
    url: "6510ece2c865e",
    method: "get",
    params,
  });
}

//福利官管理编辑
export function editwelfare(params) {
  return request({
    url: "6510ecf18f3a4",
    method: "get",
    params,
  });
}


//承包商管理
export function contractorinfo(params) {
  return request({
    url: "6510ecfcd2473",
    method: "get",
    params,
  });
}

//承包商管理编辑
export function editcontractor(params) {
  return request({
    url: "6510ed0733ba9",
    method: "get",
    params,
  });
}

//加盟商管理
export function allianceBusinessinfo(params) {
  return request({
    url: "6510ed1127c81",
    method: "get",
    params,
  });
}

//加盟商管理编辑
export function editallianceBusiness(params) {
  return request({
    url: "6510ed1c6c0d3",
    method: "get",
    params,
  });
}

//城市代理管理
export function cityAgencyinfo(params) {
  return request({
    url: "6510ed2aae688",
    method: "get",
    params,
  });
}

//城市代理管理编辑
export function editcityAgency(params) {
  return request({
    url: "6510ed35ebb84",
    method: "get",
    params,
  });
}

//提现记录列表
export function withdrawList(params) {
  return request({
    url: "6510ed44c14ef",
    method: "get",
    params,
  });
}

//提现详情
export function withdrawdec(params) {
  return request({
    url: "6510ed645de1b",
    method: "get",
    params,
  });
}

//提现通过
export function withdrawagree(params) {
  return request({
    url: "6510ed4db72c4",
    method: "get",
    params,
  });
}

//提现拒绝
export function withdrawrefuse(params) {
  return request({
    url: "6510ed58cda9a",
    method: "get",
    params,
  });
}

//轮播列表
export function carouselList(params) {
  return request({
    url: "6510ed6ed1fe3",
    method: "get",
    params,
  });
}

//新建轮播
export function addcarousel(params) {
  return request({
    url: "6510ed78b7849",
    method: "get",
    params,
  });
}

//轮播详情
export function carouseldec(params) {
  return request({
    url: "6510ed83b5d5f",
    method: "get",
    params,
  });
}

//轮播编辑
export function editcarousel(params) {
  return request({
    url: "6510ed931547d",
    method: "get",
    params,
  });
}

//轮播删除
export function delcarousel(params) {
  return request({
    url: "6510ed9b136fb",
    method: "get",
    params,
  });
}

//超时费管理详情
export function overtimepaydec(params) {
  return request({
    url: "6510eda5cab0b",
    method: "get",
    params,
  });
}

//超时费管理编辑
export function editovertimepay(params) {
  return request({
    url: "6510edb00ba32",
    method: "get",
    params,
  });
}
//承包商申请列表
export function applyforcontractorList(params) {
  return request({
    url: "6510edbe3f163",
    method: "get",
    params,
  });
}
//加盟商申请列表
export function applyforalliancebusinessList(params) {
  return request({
    url: "6510edcc4afef",
    method: "get",
    params,
  });
}

//充值金额列表
export function RechargebalanceList(params) {
  return request({
    url: "6510edd981de3",
    method: "get",
    params,
  });
}

//充值金额新增
export function addRechargebalance(params) {
  return request({
    url: "6510ee03726b6",
    method: "get",
    params,
  });
}

//充值金额详情
export function Rechargebalancedec(params) {
  return request({
    url: "6510ede32d292",
    method: "get",
    params,
  });
}

//充值金额编辑
export function editRechargebalance(params) {
  return request({
    url: "6510edee5de49",
    method: "get",
    params,
  });
}

//充值金额删除
export function delRechargebalance(params) {
  return request({
    url: "6510edf8df292",
    method: "get",
    params,
  });
}

function editagreement(data, type) {
  data.type = type;
  return request({
    url: "6510ee17cc07c",
    method: "post",
    data,
  });
}

function agreementdec(params, type) {
  params.type = type;
  return request({
    url: "6510ee0dce7b3",
    method: "get",
    params,
  });
}

//用户服务协议编辑
export function editbalanceagreement(data) {
  return editagreement(data, 1);
}

//用户服务协议详情
export function balanceagreementdec(params) {
  return agreementdec(params, 1);
}

//关于我们编辑
export function editaboutus(data) {
  return editagreement(data, 2);
}

//关于我们详情
export function aboutusdec(params) {
  return agreementdec(params, 2);
}

//用户隐私协议编辑
export function editprivacyagreement(data) {
  return editagreement(data, 3);
}

//用户隐私协议详情
export function privacyagreementdec(params) {
  return agreementdec(params, 3);
}

//用户隐私协议编辑
export function editcarwashnotice(data) {
  return editagreement(data, 4);
}

//用户隐私协议详情
export function carwashnoticedec(params) {
  return agreementdec(params, 4);
}

//电话信息详情
export function phonedec(params) {
  return request({
    url: "6510ee22b4413",
    method: "get",
    params,
  });
}

//电话信息编辑
export function editphone(params) {
  return request({
    url: "6510ee2dd28f5",
    method: "get",
    params,
  });
}

export function meituansession(params) {
  return request({
    url: "66a346686f27f",
    method: "get",
    params,
  });
}

// 导出订单
export function exportOrder(params) {
  return request({
    url: "67693b5e02b1e",
    method: "get",
    params,
  });
}
